import styled from 'styled-components'

const StyledForm = styled.form`
display: flex;
flex-direction: column;
margin: 1rem;

`

export { StyledForm }
