import React, { useContext } from 'react'
import Button from '../Components/Button/Button'
import { StyledPage } from './StyledPage'
import { DataContext } from '../Context'
import Progress from '../Components/Progress/Progress'
import Form from '../Components/Form/Form'
import Input from '../Components/Input/Input'

const Page1 = () => {
  const context = useContext(DataContext)

  return (
    <>
      <StyledPage>
        <h2>Przedstaw się :)</h2>
        <Form>
          <Input
            onChange={context.changeInputName}
            type={'text'}
            placeHolder={'Imię'}
            id = {'name'}
          >
          </Input>
          <Input
            onChange={context.changeInputName}
            placeHolder={'Nazwisko'}
            type={'text'}
            id={'surname'}
          >
          </Input>
        </Form>
        <div>
          <Button onClick={context.clickPrev}>Prev</Button>
          <Button onClick={context.clickNext}>Next</Button>
        </div>
        <Progress
          max={'100'}
          value = {'33.3'}
        >
        </Progress>
        <h4>34%</h4>
      </StyledPage>
    </>
  )
}

export default Page1
