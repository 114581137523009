import React from 'react'
import { StyledSection } from './StyledSection'
import PropTypes from 'prop-types'

const Section = (props) => {
  const { children } = props

  return (
    <StyledSection>{children}</StyledSection>
  )
}

Section.propTypes = {
  children: PropTypes.array
}

export default Section
