import React from 'react'
import { StyledButton } from './StyledButton'
import PropTypes from 'prop-types'

const Button = (props) => {
  const { onClick, children, disabled } = props
  return (
    <StyledButton
      disabled ={disabled}
      onClick={onClick}
    >{children}
    </StyledButton>
  )
}

Button.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}

export default Button
