import styled from 'styled-components'

const StyledFieldset = styled.fieldset`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
border: none;

@media screen and (max-width: 767px){
    flex-direction: column;
}
`
export { StyledFieldset }
