import React from 'react'
import { StyledInput, StyledInputRadio, StyledInputChecked } from './StyledInput'
import PropTypes from 'prop-types'

const Input = (props) => {
  const { placeHolder, type, id, onChange, checked, name, dataActivity, onInput, defaultChecked } = props

  if (type === 'text') {
    return (
      <StyledInput
        onChange={onChange}
        type={type}
        placeholder={placeHolder}
        id ={id}
        checked={checked}
        defaultChecked={defaultChecked}
        name = {name}
        data-activity ={dataActivity}
        onInput = {onInput}
      >
      </StyledInput>
    )
  } else
  if (type === 'radio') {
    return (
      <StyledInputRadio
        onChange={onChange}
        type={type}
        placeholder={placeHolder}
        id ={id}
        checked={checked}
        defaultChecked={defaultChecked}
        name = {name}
        data-activity ={dataActivity}
        onInput = {onInput}
      >
      </StyledInputRadio>
    )
  } else
  if (type === 'checkbox') {
    return (
      <StyledInputChecked
        onChange={onChange}
        type={type}
        placeholder={placeHolder}
        id ={id}
        checked={checked}
        defaultChecked={defaultChecked}
        name = {name}
        data-activity ={dataActivity}
        onInput = {onInput}
      >

      </StyledInputChecked>
    )
  }
}

Input.propTypes = {
  placeHolder: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  name: PropTypes.string,
  dataActivity: PropTypes.string,
  onInput: PropTypes.func,
  defaultChecked: PropTypes.bool
}

export default Input
