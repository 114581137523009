import React, { useState } from 'react'
import Page1 from './Pages/Page1'
import Page2 from './Pages/Page2'
import Page3 from './Pages/Page3'
import { DataContext } from './Context'

export const App = () => {
  const dataState = {
    pageNumber: 1,
    userName: '',
    userSurname: '',
    goodActivity: 0,
    badActivity: 0
  }

  const changeInputFreeTime = (e) => {
    console.log(e.target.checked)
    console.log(e)

    const inputChecked = e.target.checked
    const activity = e.target.dataset.activity

    if (inputChecked === true && activity === 'good') {
      setState({
        pageNumber: state.pageNumber,
        userName: state.userName,
        userSurname: state.userSurname,
        goodActivity: state.goodActivity + 1,
        badActivity: state.badActivity
      })
    }

    if (inputChecked === true && activity === 'bad') {
      setState({
        pageNumber: state.pageNumber,
        userName: state.userName,
        userSurname: state.userSurname,
        goodActivity: state.goodActivity,
        badActivity: state.badActivity + 1
      })
    }

    console.log(state.goodActivity)
  }

  const changeInputName = (e) => {
    console.log(e.target.id)
    const inputId = e.target.id
    const inputValue = e.currentTarget.value

    if (inputId === 'name') {
      setState({
        pageNumber: state.pageNumber,
        userName: inputValue,
        userSurname: state.userSurname,
        goodActivity: state.goodActivity,
        badActivity: state.badActivity
      })
    } else {
      setState({
        pageNumber: state.pageNumber,
        userName: state.userName,
        userSurname: inputValue,
        goodActivity: state.goodActivity,
        badActivity: state.badActivity
      })
    }
  }
  const clickPrev = (e) => {
    console.log(e)
    if (state.pageNumber <= 3 && state.pageNumber > 1) {
      setState({
        pageNumber: state.pageNumber - 1,
        userName: state.userName,
        userSurname: state.userSurname,
        goodActivity: 0,
        badActivity: 0

      })
    }
  }

  const clickNext = (e) => {
    console.log(e)
    const totalActivity = state.goodActivity + state.badActivity
    if (state.userName === '' || state.userSurname === '') {
      alert('Uzupełnij dane!')
    } else
    if (state.pageNumber === 2 && totalActivity === 0) {
      alert('Zaznacz cos :)')
    } else
    if (state.pageNumber <= 2) {
      setState({
        pageNumber: state.pageNumber + 1,
        userName: state.userName,
        userSurname: state.userSurname,
        goodActivity: state.goodActivity,
        badActivity: state.badActivity
      })
    }
  }

  const [state, setState] = useState(dataState)

  const context = {
    state: state,
    setState: setState,
    clickNext: clickNext,
    clickPrev: clickPrev,
    changeInputName: changeInputName,
    changeInputFreeTime: changeInputFreeTime
  }

  return (
    <DataContext.Provider value={context}>
      <div>
        {state.pageNumber === 1 ? <Page1></Page1> : null}
        {state.pageNumber === 2 ? <Page2></Page2> : null}
        {state.pageNumber === 3 ? <Page3></Page3> : null}
      </div>
    </DataContext.Provider>

  )
}

export default App
