import styled from 'styled-components'

const StyledProgress = styled.progress`
height: 60px;
width: 300px;
accent-color: #8458B3; 

   
`

export { StyledProgress }
