import styled from 'styled-components'

const StyledPage = styled.div`
display: flex;
align-items: center;
justify-content: center;
padding: 10px;
flex-direction: column;
color:  #a0d2eb;
h4{
    color: #8458B3;
}

`
export { StyledPage }
