import React from 'react'
import { StyledProgress } from './StyledProgress'
import PropTypes from 'prop-types'

const Progress = (props) => {
  const { max, value } = props

  return (

    <StyledProgress
      max={max}
      value={value}
    >
    </StyledProgress>

  )
}

Progress.propTypes = {
  max: PropTypes.string,
  value: PropTypes.string

}

export default Progress
