import React from 'react'
import { StyledFieldset } from './StyledFieldset'
import PropTypes from 'prop-types'

const Fieldset = (props) => {
  const { children } = props

  return (
    <StyledFieldset>{children}</StyledFieldset>
  )
}

Fieldset.propTypes = {
  children: PropTypes.array
}

export default Fieldset
