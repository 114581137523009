import React, { useContext } from 'react'
import Button from '../Components/Button/Button'
import { StyledPage } from './StyledPage'
import { DataContext } from '../Context'
import Progress from '../Components/Progress/Progress'
import Section from '../Components/Section/Section'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFaceFrown, faFaceSmile } from '@fortawesome/free-solid-svg-icons'

const Page3 = () => {
  const context = useContext(DataContext)

  return (
    <>
      <StyledPage>
        <h2>Twój wynik</h2>
        <Section>
          <h3>{context.state.userName} {context.state.userSurname}</h3>
          {context.state.goodActivity >= context.state.badActivity
            ? <FontAwesomeIcon
                style={{ color: 'green', fontSize: '100px' }}
                icon={faFaceSmile}
              />
            : <FontAwesomeIcon
                style={{ color: 'red', fontSize: '100px' }}
                icon={faFaceFrown}
              />}
          <h3>
            {context.state.goodActivity >= context.state.badActivity ? 'Prawdopodobnie prowadzisz zdrowy tryb życia. Super!' : 'Twój styl życia nie wygląda dobrze...'}

          </h3>
        </Section>

        <div>
          <Button

            onClick={context.clickPrev}
          >Prev
          </Button>
          {context.state.pageNumber > 3 ? <Button onClick={context.clickPrev}>Next</Button> : null}
        </div>
        <Progress
          max={'100'}
          value = {'100'}
        >

        </Progress>
        <h4>100%</h4>
      </StyledPage>
    </>
  )
}

export default Page3
