import styled from 'styled-components'

const StyledInput = styled.input`
margin: .5rem;
padding-left:1rem;
font-weight: 700;
border: none;
    border-radius: 0.5rem;
    height: 2rem;
box-shadow: ${props => {
    // eslint-disable-next-line default-case
    switch (props.type) {
        case 'checkbox':
            return 'none'
            case 'text':
                return '-2px -2px 6px rgba(255, 255, 255, .7), -2px -2px 4px rgba(255, 255, 255, .5), 2px 2px 2px rgba(255, 255, 255, .075), 2px 2px 4px rgba(0, 0, 0, .15)'
                case 'radio':
                return 'none'
    }
}};

`

const StyledInputRadio = styled.input`
-webkit-appearance: none;
width: 20px;
height: 20px;
margin: 30px;
background-color: #eeeeee;
box-shadow: 
5px 5px 10px rgba(0,0,0,.2),
-5px -5px 10px rgba(255,255,255,1),
 inset 0 0 0 rgba(0,0,0,.2),
 inset 0 0 0 rgba(255,255,255,.2);
border-radius: 50%;
outline: none;
cursor: pointer;
transition: all .3s ease-in-out;
:checked{
    background-color: #efefef;
    box-shadow: 
    0 0 0 rgba(0,0,0,.2),
    0 0 0 rgba(255,255,255,1),
inset 5px 5px 10px rgba(0,0,0,.2),
inset -5px -5px 10px rgba(255,255,255,.2);
}

`
const StyledInputChecked = styled.input`
-webkit-appearance: none;
border-radius: 20%;
width: 20px;
height: 20px;
margin: 30px;
background-color: white;
box-shadow: 
5px 5px 10px rgba(0,0,0,.2),
-5px -5px 10px rgba(255,255,255,1),
 inset 0 0 0 rgba(0,0,0,.2),
 inset 0 0 0 rgba(255,255,255,.2);
outline: none;
cursor: pointer;
transition: all .3s ease-in-out;
:checked{
    background-color: #8458B3 ;
    box-shadow: 
    0 0 0 rgba(0,0,0,.2),
    0 0 0 rgba(255,255,255,1),
inset 5px 5px 10px rgba(0,0,0,.2),
inset -5px -5px 10px rgba(255,255,255,.2);
}

`

export { StyledInput, StyledInputRadio, StyledInputChecked }
