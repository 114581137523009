import styled from 'styled-components'

const StyledSection = styled.section`
padding: 1rem;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
color: #a28089;
box-shadow: -6px -6px 14px rgba(255, 255, 255, .7),
              -6px -6px 10px rgba(255, 255, 255, .5),
              6px 6px 8px rgba(255, 255, 255, .075),
              6px 6px 10px rgba(0, 0, 0, .15);
`

export { StyledSection }
