import React, { useContext } from 'react'
import Button from '../Components/Button/Button'
import { StyledPage } from './StyledPage'
import { DataContext } from '../Context'
import Progress from '../Components/Progress/Progress'
import Input from '../Components/Input/Input'
import Fieldset from '../Components/Fieldset/Fieldset'
import Section from '../Components/Section/Section'
const Page2 = () => {
  const context = useContext(DataContext)

  return (

    <>
      <StyledPage>
        <h2>Co najlepiej opisuje Twoją osobę?</h2>
        <Section>
          <h3>W wolnych chwilach:</h3>
          <Fieldset>
            <Input
              onChange={context.changeInputFreeTime}
              id={'videoGame'}
              type={'checkbox'}
              dataActivity = {'bad'}
            >
            </Input>
            <label htmlFor = {'videoGame'}>gram na konsoli</label>

            <Input
              onChange={context.changeInputFreeTime}
              id={'movie'}
              type={'checkbox'}
              dataActivity = {'bad'}
            >
            </Input>
            <label htmlFor = {'movie'}>oglądam seriale</label>

            <Input
              onChange={context.changeInputFreeTime}
              id={'run'}
              type={'checkbox'}
              dataActivity = {'good'}
            >
            </Input>
            <label htmlFor = {'run'}>biegam</label>

            <Input
              onChange={context.changeInputFreeTime}
              id={'activeSports'}
              type={'checkbox'}
              dataActivity = {'good'}
            >
            </Input>
            <label htmlFor = {'activeSports'}>uprawiam inne sporty na świeżym powietrzu</label>

            <Input
              onChange={context.changeInputFreeTime}
              id={'sleep'}
              type={'checkbox'}
              dataActivity = {'bad'}
            >
            </Input>
            <label htmlFor = {'sleep'}>śpię</label>

            <Input
              onChange={context.changeInputFreeTime}
              id={'gym'}
              type={'checkbox'}
              dataActivity = {'good'}
            >
            </Input>
            <label htmlFor = {'gym'}>chodzę na siłownię</label>

          </Fieldset>

          <h3>Czy zwracasz uwagę na swoje posiłki?</h3>
          <Fieldset>
            <Input
              onInput={context.changeInputFreeTime}
              id={'foodYes'}
              type={'radio'}
              name = {'food'}
              dataActivity = {'good'}
            >
            </Input>
            <label htmlFor = {'foodYes'}>tak</label>

            <Input
              onInput={context.changeInputFreeTime}
              id={'foodNo'}
              type={'radio'}
              name = {'food'}
              dataActivity = {'bad'}

            >
            </Input>
            <label htmlFor = {'foodNo'}>nie</label>
          </Fieldset>
        </Section>

        <div>
          <Button onClick={context.clickPrev}>Prev</Button>
          <Button onClick={context.clickNext}>Next</Button>
        </div>
        <Progress
          max={'100'}
          value = {'66.6'}
        >
        </Progress>
        <h4>66%</h4>
      </StyledPage>
    </>
  )
}

export default Page2
